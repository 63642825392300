import type { WorkplaceTestingOrder } from 'careos-maestro';
import { format } from 'date-fns';

const formatDate = (dateString: string) => format(new Date(dateString), 'yyyy-MM-dd');

const parseSamplingPeriod = ({ samplingPeriod }: Pick<WorkplaceTestingOrder, 'samplingPeriod'>) => {
  const isRange = samplingPeriod.start !== samplingPeriod.end;

  const formattedStartDate = formatDate(samplingPeriod.start);
  const formattedEndDate = formatDate(samplingPeriod.end);

  const formattedString = isRange
    ? `${formattedStartDate} - ${formattedEndDate}`
    : formattedStartDate;

  return {
    isRange,
    formattedString,
  };
};

export { parseSamplingPeriod };
