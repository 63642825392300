import { queryOptions, useQuery } from '@tanstack/react-query';
import type { GetRequisitionsBySessionIdResponseDto } from 'careos-maestro';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { QueryConfig } from '@/lib/react-query';

export type GetRequisitionsBySessionIdParams = { sessionId: string };
export const useGetRequisitionsBySessionId = ({
  queryConfig,
  getRequisitionsBySessionIdParams,
}: {
  getRequisitionsBySessionIdParams: GetRequisitionsBySessionIdParams;
  queryConfig?: QueryConfig<typeof getRequisitionsBySessionIdQueryOptions>;
}) => {
  const { get } = useCustomFetch();

  function getRequisitionsBySessionId({ sessionId }: GetRequisitionsBySessionIdParams) {
    return get<GetRequisitionsBySessionIdResponseDto>(
      `/requisitions/get-requisitions-by-session-id/${sessionId}`,
    );
  }

  function getRequisitionsBySessionIdQueryOptions({ sessionId }: GetRequisitionsBySessionIdParams) {
    return queryOptions({
      queryKey: ['get-requisitions-by-session-id', sessionId],
      queryFn: () => getRequisitionsBySessionId({ sessionId }),
    });
  }

  return useQuery({
    ...getRequisitionsBySessionIdQueryOptions(getRequisitionsBySessionIdParams),
    ...queryConfig,
  });
};
