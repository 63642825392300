import { Badge } from '@careos/react-ui/Badge';
import { Button } from '@careos/react-ui/Button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@careos/react-ui/Tooltip';
import type { OrderReason } from '@careos/toxicology-types';
import { createColumnHelper, type ColumnDef } from '@tanstack/react-table';
import type { OrderStatus, WorkplaceTestingOrder } from 'careos-maestro';
import { format } from 'date-fns';
import { CalendarPlus, EyeOff } from 'lucide-react';

import i18n from '@/i18n';
import { parseSamplingPeriod } from '@/utils/sampling-period';

const columnHelper = createColumnHelper<WorkplaceTestingOrder>();

const getSeverity = (status: OrderStatus) => {
  switch (status) {
    case 'requested':
      return 'bg-red-500';
    case 'assigned':
      return 'bg-yellow-500';
    case 'session_started':
      return 'bg-blue-500';
    case 'session_closed':
      return 'bg-green-500';
    case 'session_reported':
      return 'bg-gray-500';
    default:
      throw new Error(`Received unsupported status: ${status}`);
  }
};

export const createColumns = (
  handleOpenDialog: (order: WorkplaceTestingOrder) => void,
): ColumnDef<WorkplaceTestingOrder, any>[] => [
  columnHelper.display({
    id: 'expander',
  }),
  columnHelper.accessor('orderId', {
    header: i18n.t('table.header.order_id'),
    cell: ({ getValue }) => getValue().slice(0, 6),
  }),
  columnHelper.accessor('samplingPeriod', {
    header: i18n.t('table.header.sampling_period'),
    cell: ({ getValue }) => {
      const { formattedString } = parseSamplingPeriod({
        samplingPeriod: getValue(),
      });
      return formattedString;
    },
  }),
  columnHelper.accessor('requester.name', {
    header: i18n.t('table.header.organization'),
    cell: ({ getValue }) => getValue(),
  }),
  columnHelper.accessor('requester.workplace', {
    header: i18n.t('table.header.employer'),
    cell: ({ getValue }) => getValue(),
  }),
  columnHelper.accessor('reasonForTesting', {
    header: i18n.t('table.header.reason_for_testing'),
    cell: ({ getValue }) => {
      return i18n.t(`reason.${getValue<OrderReason>()}`);
    },
  }),
  columnHelper.accessor('notes', {
    header: i18n.t('table.header.more_information'),
    cell: ({ getValue }) => getValue() ?? '-',
  }),
  columnHelper.display({
    id: 'actions',
    header: i18n.t('table.header.actions'),
    cell: ({ row, table }) => {
      const orderId = row.getValue('orderId') as string;

      // Function to get the full order data when needed
      const getOrder = (): WorkplaceTestingOrder | undefined => {
        const orderIndex = table.options.data.findIndex(
          (order: WorkplaceTestingOrder) => order.orderId === orderId,
        );
        return orderIndex !== -1
          ? (table.options.data[orderIndex] as WorkplaceTestingOrder)
          : undefined;
      };
      const order = getOrder();
      if (!order) return;
      return (
        <div className="flex">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon" onClick={() => handleOpenDialog(order)}>
                  <span className="sr-only">{i18n.t('table.create_session')}</span>
                  <CalendarPlus className="size-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>{i18n.t('table.create_session')}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <Button variant="ghost" size="icon">
            <EyeOff className="size-4 text-gray-500 opacity-70" />
          </Button>
        </div>
      );
    },
  }),
  columnHelper.accessor('orderStatus', {
    header: i18n.t('table.header.status'),
    cell: ({ getValue }) => {
      const severity = getSeverity(getValue());
      return (
        <Badge className={`font-bold ${severity}`} variant={'static'}>
          {i18n.t(`status.${getValue<OrderStatus>()}`)}
        </Badge>
      );
    },
  }),
  columnHelper.accessor('orderDate', {
    header: i18n.t('table.header.order_date'),
    cell: ({ getValue }) => {
      const date = new Date(getValue());
      const formattedDate = format(date, 'yyyy-MM-dd');
      return <label className="text-gray-400">{formattedDate}</label>;
    },
  }),
];
