import { ToxicologyActivityIdentifier, SamplingKitIdentifier } from '@careos/identifiers';
import type { PanelSize } from '@careos/toxicology-types';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useSessionOptions } from '@/features/order/form/api/get-session-options';
import type { QueryConfig } from '@/lib/react-query';

interface PanelOptionsResult {
  panelOptions: PanelSize[] | null;
  additionalSubstances: { value: string; label: string }[];
}

export const usePanelOptions = ({
  sampleType,
  samplingKit,
  panelSize,
  queryConfig,
}: {
  sampleType?: ToxicologyActivityIdentifier;
  samplingKit?: SamplingKitIdentifier;
  panelSize?: PanelSize;
  queryConfig?: QueryConfig<typeof getPanelOptions>;
} = {}) => {
  const { data: sessionOptions } = useSessionOptions();
  const { t } = useTranslation('translation');

  // TODO: This is implemented incorrectly. Follow convention in other queries.
  function getPanelOptions(): Promise<PanelOptionsResult> {
    if (!sampleType || !sessionOptions) {
      return Promise.resolve({ panelOptions: null, additionalSubstances: [] });
    }

    return sessionOptions.getAdditionalSubstances(sampleType, samplingKit).then((data) => {
      const panelOptions = data.panels ? (Object.keys(data.panels).sort() as PanelSize[]) : null;

      const additionalSubstancesOptions =
        panelSize && data.panels && data.panels[panelSize]
          ? data.panels[panelSize].additionalSubstances || []
          : [];

      const additionalSubstances = additionalSubstancesOptions.map((substance) => ({
        value: substance,
        label: t(`${substance}`, {
          defaultValue: substance,
        }) as string,
      }));

      return { panelOptions, additionalSubstances };
    });
  }

  const getPanelOptionsQueryOptions = () => {
    return queryOptions({
      queryKey: ['panel-options', sampleType, samplingKit, panelSize],
      queryFn: () => getPanelOptions(),
      enabled: !!sampleType && !!sessionOptions,
    });
  };

  return useQuery({
    ...getPanelOptionsQueryOptions(),
    ...queryConfig,
  });
};
