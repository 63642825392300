import { Button } from '@careos/react-ui/Button';
import { useToast } from '@careos/react-ui/Toast';
import { Copy } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { useCreateRandomizationListUploadUrl } from '../api/create-randomization-list-upload-url';

export const RandomizationListButton = ({ sessionId }: { sessionId: string }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const createUrlMutation = useCreateRandomizationListUploadUrl({
    mutationConfig: {
      onSuccess: async (data) => {
        await navigator.clipboard.writeText(data.url);
        toast({
          description: t('randomizationList.copyLinkToastMessage'),
        });
      },
    },
  });

  const generateLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    createUrlMutation.mutate(sessionId);
  };

  return (
    <Button onClick={generateLink} variant="secondary">
      {t('randomizationList.copyLink')}
      <Copy className="size-4" />
    </Button>
  );
};
