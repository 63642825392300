export const CareosRegex = {
  /**
   * Checks that a barcode is of CareOS standard.
   * Only Uppercase, alphanumeric and can contain - (dashes) and _ (underscores)
   */
  BARCODE: /^[A-Z0-9-_]*$/,
  /**
   * checks that a date is of format yyyy-MM-DD
   */
  ISO_DATE: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
  /**
   * checks that a time is of format HH:mm or HH:mm - HH:mm
   */
  TIME_SLOT:
    /^([01]\d|2[0-3]):[0-5]\d$|^([01]\d|2[0-3]):[0-5]\d\s?-\s?([01]\d|2[0-3]):[0-5]\d$/,

  /**
   * checks that a time is of format HH:mm
   */
  TIME_SLOT_HH_MM: /^([01]\d|2[0-3]):[0-5]\d$/,
} as const;
