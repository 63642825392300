import type { SamplingKitIdentifier, ToxicologyActivityIdentifier } from '@careos/identifiers';
import type { TestType } from '@careos/organization-api-types';
import type { GetPanelsWithAdditionalSubstancesResponseDto } from '@careos/toxicology-types';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { QueryConfig } from '@/lib/react-query';

interface SessionOptions {
  testTypes: TestType[];
  sampleTypes: ToxicologyActivityIdentifier[];
  samplingKits: SamplingKitIdentifier;
  getAdditionalSubstances: (
    activityDefinition: ToxicologyActivityIdentifier,
    samplingKit?: SamplingKitIdentifier,
  ) => Promise<GetPanelsWithAdditionalSubstancesResponseDto>;
}

const endpoints = {
  getTestTypeOptions: '/config/get-test-types',
  getSampleTypeOptions: '/config/get-sample-types',
  getEnabledSamplingKits: '/config/get-sampling-kits',
  getAdditionalSubstances: '/config/panel-options',
};

// TODO: This is implemented incorrectly. Follow convention in other queries.
const getSessionOptions = async (
  get: ReturnType<typeof useCustomFetch>['get'],
): Promise<SessionOptions> => {
  const [testTypes, sampleTypes, samplingKits] = await Promise.all([
    get<TestType[]>(endpoints.getTestTypeOptions),
    get<ToxicologyActivityIdentifier[]>(endpoints.getSampleTypeOptions),
    get<SamplingKitIdentifier>(endpoints.getEnabledSamplingKits),
  ]);

  const getAdditionalSubstances = (
    activityDefinition: ToxicologyActivityIdentifier,
    samplingKit?: SamplingKitIdentifier,
  ) =>
    get<GetPanelsWithAdditionalSubstancesResponseDto>(
      `${endpoints.getAdditionalSubstances}?activityDefinition=${activityDefinition}${
        samplingKit ? `&samplingKit=${samplingKit}` : ''
      }`,
    );

  return {
    testTypes,
    sampleTypes,
    samplingKits,
    getAdditionalSubstances,
  };
};

export const useSessionOptions = ({
  queryConfig,
}: {
  queryConfig?: QueryConfig<typeof getSessionOptions>;
} = {}) => {
  const { get } = useCustomFetch();

  const queryFn = useCallback(() => getSessionOptions(get), [get]);

  return useQuery({
    queryKey: ['session-options', get],
    queryFn,
    ...queryConfig,
  });
};
