import { useAuth0 } from '@auth0/auth0-react';
import { type Auth0CareosUser } from '@careos/react-ui/AuthenticatedComponent';
import { AuthProvider } from '@careos/react-ui/AuthProvider';
import { BackgroundLayout } from '@careos/react-ui/Layouts';
import { ErrorComponentSentryDefault } from '@careos/react-ui/Router';
import { ThemeProvider } from '@careos/react-ui/ThemeProvider';
import { initializeSentry } from '@careos/react-ui/utils';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { useTranslation } from 'react-i18next';

import { queryClient } from '../lib/react-query';

import { routeTree } from './routeTree.gen';

import '../i18n';

const router = createRouter({
  routeTree,
  notFoundMode: 'root',
  context: { auth: undefined! },
  defaultErrorComponent: ({ error }) => (
    <div className="grid min-h-[calc(100dvh_-_58px)] place-content-center">
      <ErrorComponentSentryDefault error={error} errorDescription={error.message} />
    </div>
  ),

  defaultNotFoundComponent: NotFoundComponent,
});

initializeSentry(router);

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
function InnerApp() {
  const auth = useAuth0<Auth0CareosUser>();

  // INFO: Make sure auth context is loaded before injecting it into the router context
  if (auth.isLoading) {
    return <BackgroundLayout />;
  }

  return (
    <BackgroundLayout>
      <RouterProvider router={router} context={{ auth }} />
    </BackgroundLayout>
  );
}

function NotFoundComponent() {
  const { t } = useTranslation('translation');
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <img src="/abclabs.svg" alt="abclogo" className="m-6 h-36 rounded-md p-4" />
      <p className="text-4xl">{t('nav.not_found')}</p>
    </div>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider>
          <InnerApp />
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
