import { DonorInteractionTypeSchema } from '@abc-labs-ab/ts-events';
import {
  SpecimenSamplingKit,
  SamplingKitIdentifier,
  ToxicologyActivityIdentifier,
  ToxicologyActivityIdentifierSchema,
  SamplingKitIdentifierSchema,
  isDoaActivityIdentifierWithSamplingKit,
} from '@careos/identifiers';
import { TestType, TestTypeSchema } from '@careos/organization-api-types';
import { Button } from '@careos/react-ui/Button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@careos/react-ui/Form';
import { Input } from '@careos/react-ui/Input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@careos/react-ui/Select';
import { SelectBox } from '@careos/react-ui/SelectBox';
import { useToast } from '@careos/react-ui/Toast';
import {
  PanelSize,
  PanelSizeSchema,
  type ToxicologySamplingSessionDetails,
} from '@careos/toxicology-types';
import { nonEmptyString } from '@careos/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { type CreateSessionOrderRequestDto, type WorkplaceTestingOrder } from 'careos-maestro';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useCreateSession } from '@/features/order/form/api/create-session';
import { usePanelOptions } from '@/features/order/form/api/get-panel-options';
import { parseSamplingPeriod } from '@/utils/sampling-period';

import { useCollectorOptions } from '../api/get-collector-options';

import { DatePicker } from './calendar';

const sessionFormSchema = z.object({
  practitionerId: nonEmptyString,
  donorInteractionType: DonorInteractionTypeSchema,
  sessionOrder: z.object({
    samplingDate: nonEmptyString,
    timeSlot: nonEmptyString,
    sampleCount: nonEmptyString,
    samplingDetails: z
      .array(
        z.object({
          testType: z.array(TestTypeSchema),
          sampleType: ToxicologyActivityIdentifierSchema.nullish(),
          samplingKit: SamplingKitIdentifierSchema.nullish(),
          panelSize: PanelSizeSchema.nullish(),
          additionalSubstances: z.string().array().nullish(),
        }),
      )
      .min(1, 'At least one sampling detail is required'),
  }),
});

export type SessionFormData = z.infer<typeof sessionFormSchema>;

interface SessionFormProps {
  order: WorkplaceTestingOrder;
  testTypeOptions: TestType[];
  sampleTypeOptions: ToxicologyActivityIdentifier[];
  onSubmitSuccess: () => void;
}

export const SessionForm = ({
  order,
  testTypeOptions,
  sampleTypeOptions,
  onSubmitSuccess,
}: SessionFormProps) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const defaultTestTypes = order.samplingDetails.map((it) => it.testType);
  const defaultDoaSamplingDetails = order.samplingDetails.find(
    (it) => it.testType === TestType.DoA,
  );

  const samplingPeriod = parseSamplingPeriod({
    samplingPeriod: order.samplingPeriod,
  });

  const form = useForm<SessionFormData>({
    resolver: zodResolver(sessionFormSchema),
    defaultValues: {
      donorInteractionType: order.donorInteractionType ?? undefined,
      sessionOrder: {
        samplingDate: order.samplingPeriod.start,
        timeSlot: order.samplingTimeSlot || '',
        sampleCount: '1',
        samplingDetails: [
          {
            testType: defaultTestTypes,
            sampleType: defaultDoaSamplingDetails?.sampleType || undefined,
            samplingKit: defaultDoaSamplingDetails?.samplingKit || undefined,
            panelSize: defaultDoaSamplingDetails?.panelSize || undefined,
            additionalSubstances: defaultDoaSamplingDetails?.additionalSubstances || [],
          },
        ],
      },
    },
  });

  const { mutate: createSession } = useCreateSession({
    mutationConfig: {
      onSuccess: () => {
        form.reset();
        onSubmitSuccess();
        toast({
          description: t('sessionForm.sessionCreationToastMessage.success'),
        });
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          title: t('sessionForm.sessionCreationToastMessage.error'),
          description: error.message,
        });
      },
    },
  });

  const testTypeWatch = form.watch('sessionOrder.samplingDetails.0.testType');
  const sampleTypeWatch = form.watch('sessionOrder.samplingDetails.0.sampleType');
  const samplingKitWatch = form.watch('sessionOrder.samplingDetails.0.samplingKit');
  const panelSizeWatch = form.watch('sessionOrder.samplingDetails.0.panelSize');

  const isDoASelected = testTypeWatch.includes(TestType.DoA);

  const samplingKitOptions = isDoaActivityIdentifierWithSamplingKit(sampleTypeWatch)
    ? SpecimenSamplingKit[sampleTypeWatch]
    : [];

  const { data: panelData } = usePanelOptions({
    sampleType: sampleTypeWatch || undefined,
    samplingKit: samplingKitWatch || undefined,
    panelSize: panelSizeWatch || undefined,
  });

  const { data: collectors } = useCollectorOptions({
    organizationKey: order.requester.key,
  });

  const panelOptions = panelData?.panelOptions ?? null;
  const additionalSubstances = panelData?.additionalSubstances ?? [];

  const onSubmit = (data: SessionFormData) => {
    const samplingDetails: ToxicologySamplingSessionDetails[] = [];

    const isPEthSelected = data.sessionOrder.samplingDetails.every(
      (detail) => detail.testType.length === 1 && detail.testType[0] === TestType.PEth,
    );

    if (isPEthSelected) {
      samplingDetails.push({
        testType: TestType.PEth,
        sampleType: ToxicologyActivityIdentifier.PETH_DRIED_BLOOD_SPOTS,
        samplingKit: SamplingKitIdentifier.DRIED_BLOOD_SPOTS,
        panelSize: PanelSize.SG,
        additionalSubstances: [],
      });
    } else {
      data.sessionOrder.samplingDetails.forEach((detail) => {
        if (detail.testType.includes(TestType.DoA)) {
          if (!detail.sampleType || !detail.panelSize) {
            throw new Error('Sample type and panel size are required for DoA tests');
          }
          samplingDetails.push({
            testType: TestType.DoA,
            sampleType: detail.sampleType as ToxicologyActivityIdentifier,
            samplingKit: detail.samplingKit as SamplingKitIdentifier,
            panelSize: detail.panelSize as PanelSize,
            additionalSubstances: detail.additionalSubstances,
          });
        }
        if (detail.testType.includes(TestType.PEth)) {
          samplingDetails.push({
            testType: TestType.PEth,
            sampleType: ToxicologyActivityIdentifier.PETH_DRIED_BLOOD_SPOTS,
            samplingKit: SamplingKitIdentifier.DRIED_BLOOD_SPOTS,
            panelSize: PanelSize.SG,
            additionalSubstances: [],
          });
        }
      });
    }

    const practitionerName = collectors?.find((it) => it.auth0UserId === data.practitionerId)?.name;

    if (!practitionerName) {
      throw new Error('Collector not found');
    }

    const dto: CreateSessionOrderRequestDto = {
      practitionerId: data.practitionerId,
      practitionerName,
      orderId: order?.orderId,
      sessionOrder: {
        samplingDate: format(new Date(data.sessionOrder.samplingDate), 'yyyy-MM-dd'),
        timeSlot: data.sessionOrder.timeSlot,
        sampleCount: Number(data.sessionOrder.sampleCount),
        samplingDetails,
        donorInteractionType: data.donorInteractionType,
      },
    };
    createSession(dto);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="donorInteractionType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('generic.donorInteractionType.label')}</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t('generic.donorInteractionType.placeholder')} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {DonorInteractionTypeSchema.options.map((type) => (
                      <SelectItem key={type} value={type}>
                        {t(`generic.donorInteractionType.options.${type}`)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {collectors && (
          <FormField
            control={form.control}
            name="practitionerId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('sessionForm.collector')}</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t('sessionForm.collectorPlaceholder')} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {collectors.map((collector) => (
                      <SelectItem key={collector.auth0UserId} value={collector.auth0UserId}>
                        {collector.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <FormField
          control={form.control}
          name="sessionOrder.samplingDate"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>{t('sessionForm.samplingDate')}</FormLabel>
              <DatePicker field={field} />
              {samplingPeriod.isRange && (
                <FormDescription>
                  {t('sessionForm.samplingDateRequestedRange', {
                    rangeString: samplingPeriod.formattedString,
                  })}
                </FormDescription>
              )}
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="sessionOrder.timeSlot"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('sessionForm.timeSlot')}</FormLabel>
              <FormControl>
                <Input placeholder={t('sessionForm.timeSlotPlaceholder')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="sessionOrder.sampleCount"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('sessionForm.sampleCount')}</FormLabel>
              <Input type="number" {...field} />
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="sessionOrder.samplingDetails.0.testType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('sessionForm.testTypes')}</FormLabel>
              <SelectBox
                options={testTypeOptions.map((testType) => ({
                  value: testType,
                  label: testType,
                }))}
                value={field.value}
                onChange={field.onChange}
                placeholder={t('sessionForm.testTypesPlaceholder')}
                multiple
              />
              <FormMessage />
            </FormItem>
          )}
        />

        {isDoASelected && (
          <>
            <FormField
              control={form.control}
              name="sessionOrder.samplingDetails.0.sampleType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('sessionForm.sampleTypes')}</FormLabel>
                  <Select
                    key={field.value}
                    onValueChange={(val) => {
                      field.onChange(val);
                      form.setValue('sessionOrder.samplingDetails.0.samplingKit', null);
                      form.setValue('sessionOrder.samplingDetails.0.panelSize', null);
                      form.setValue('sessionOrder.samplingDetails.0.additionalSubstances', null);
                    }}
                    value={field.value || ''}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={t('sessionForm.sampleTypesPlaceholder')} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {sampleTypeOptions.map((sampleType) => (
                        <SelectItem key={sampleType} value={sampleType}>
                          {t(`sample_types.${sampleType}`)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {samplingKitOptions && samplingKitOptions.length > 0 && (
              <FormField
                control={form.control}
                name="sessionOrder.samplingDetails.0.samplingKit"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('sessionForm.samplingKits')}</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value || ''}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t('sessionForm.samplingKitsPlaceholder')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {samplingKitOptions.map((kit) => (
                          <SelectItem key={kit} value={kit}>
                            {t(`sampling_kits.${kit}`)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {panelOptions && (
              <FormField
                control={form.control}
                name="sessionOrder.samplingDetails.0.panelSize"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('sessionForm.panelSize')}</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value || ''}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t('sessionForm.panelSizePlaceholder')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {panelOptions.map((size) => (
                          <SelectItem key={size} value={size}>
                            {t(`panel.${size}`)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="sessionOrder.samplingDetails.0.additionalSubstances"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('sessionForm.additionalSubstances')}</FormLabel>
                  <SelectBox
                    options={additionalSubstances}
                    value={field.value || []}
                    onChange={field.onChange}
                    emptyPlaceholder={t('sessionForm.noAdditionalSubstances')}
                    placeholder={t('sessionForm.additionalSubstancesPlaceholder')}
                    multiple
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}

        <Button type="submit" className="mt-5 w-full py-3">
          {t('sessionForm.createSessionButtonLabel')}
        </Button>
      </form>
    </Form>
  );
};
