import type { SamplingKitIdentifier, ToxicologyActivityIdentifier } from '@careos/identifiers';
import { Button } from '@careos/react-ui/Button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@careos/react-ui/Collapsible';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@careos/react-ui/Table';
import { Tabs, TabsList, TabsTrigger } from '@careos/react-ui/Tabs';
import { PanelSize } from '@careos/toxicology-types';
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { OrderStatus, type SamplingSession, type WorkplaceTestingOrder } from 'careos-maestro';
import { ChevronRight } from 'lucide-react';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { SessionDetail } from './row-expansion-table/session-columns';
import { SessionDetailsTable } from './row-expansion-table/session-data-table';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

type TabType = 'all' | OrderStatus;

type AccumulatedSamplingDetails = {
  panelSize: PanelSize[];
  sampleTypes: ToxicologyActivityIdentifier[];
  samplingKits: SamplingKitIdentifier[];
  additionalSubstances: string[];
};

export function DataTable<TData extends WorkplaceTestingOrder, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const { t } = useTranslation();
  const [openCollapsibles, setOpenCollapsibles] = useState<Set<string>>(new Set());
  const [selectedTab, setSelectedTab] = useState<TabType>('all');

  const filterDataByStatus = useCallback((data: TData[], status: OrderStatus): TData[] => {
    return data.filter((it) => it.orderStatus === status);
  }, []);

  const getFilteredData = useCallback(
    (status: TabType) => {
      switch (status) {
        case OrderStatus.REQUESTED:
          return filterDataByStatus(data, OrderStatus.REQUESTED);
        case OrderStatus.ASSIGNED:
          return filterDataByStatus(data, OrderStatus.ASSIGNED);
        case OrderStatus.SESSION_STARTED:
          return filterDataByStatus(data, OrderStatus.SESSION_STARTED);
        case OrderStatus.SESSION_CLOSED:
          return filterDataByStatus(data, OrderStatus.SESSION_CLOSED);
        case OrderStatus.SESSION_REPORTED:
          return filterDataByStatus(data, OrderStatus.SESSION_REPORTED);
        default:
          return data;
      }
    },
    [data, filterDataByStatus],
  );
  const getAccumulatedSamplingDetails = (
    samplingDetails: WorkplaceTestingOrder['samplingDetails'],
  ) => {
    const accumulatedSamplingDetails = samplingDetails.reduce<AccumulatedSamplingDetails>(
      (acc, { panelSize, sampleType, samplingKit, additionalSubstances }) => {
        if (panelSize) acc.panelSize.push(panelSize);
        if (sampleType) acc.sampleTypes.push(sampleType);
        if (samplingKit) acc.samplingKits.push(samplingKit);
        if (additionalSubstances) acc.additionalSubstances.push(...additionalSubstances);
        return acc;
      },
      {
        panelSize: [],
        sampleTypes: [],
        samplingKits: [],
        additionalSubstances: [],
      },
    );
    return accumulatedSamplingDetails;
  };
  const parseSession = (session: SamplingSession): SessionDetail => ({
    donorInteractionType: session.donorInteractionType,
    createdAt: session.createdAt,
    sessionId: session.sessionId,
    samplingDate: `${session.samplingDate} ${session.timeSlot}`,
    sampleCount: session.sampleCount,
    practitioner: session.practitioner,
    ...getAccumulatedSamplingDetails(session.samplingDetails),
  });

  const filteredData = useMemo(() => getFilteredData(selectedTab), [selectedTab, getFilteredData]);

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  useEffect(() => {
    table.setPageIndex(0);
  }, [selectedTab, table]);

  return (
    <>
      <Tabs
        className="my-4 max-w-fit overflow-auto"
        defaultValue="all"
        onValueChange={(value) => setSelectedTab(value as TabType)}
      >
        <TabsList>
          <TabsTrigger value="all">{t('table.tabs.all')}</TabsTrigger>
          <TabsTrigger value={OrderStatus.REQUESTED}>{t('table.tabs.requested')}</TabsTrigger>
          <TabsTrigger value={OrderStatus.ASSIGNED}>{t('table.tabs.assigned')}</TabsTrigger>
          <TabsTrigger value={OrderStatus.SESSION_STARTED}>{t('table.tabs.started')}</TabsTrigger>
          <TabsTrigger value={OrderStatus.SESSION_CLOSED}>{t('table.tabs.closed')}</TabsTrigger>
          <TabsTrigger value={OrderStatus.SESSION_REPORTED}>{t('table.tabs.reported')}</TabsTrigger>
        </TabsList>
      </Tabs>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                <TableHead></TableHead>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <React.Fragment key={row.id}>
                  <TableRow className="font-medium">
                    <TableCell>
                      {row.original.sessions?.length ? (
                        <Collapsible
                          open={openCollapsibles.has(row.id)}
                          onOpenChange={(isOpen) => {
                            setOpenCollapsibles((prev) => {
                              const newSet = new Set(prev);
                              if (isOpen) {
                                newSet.add(row.id);
                              } else {
                                newSet.delete(row.id);
                              }
                              return newSet;
                            });
                          }}
                        >
                          <CollapsibleTrigger asChild>
                            <Button variant="ghost" size="icon" data-test-id="expand-button">
                              <ChevronRight
                                className={`size-4 ${
                                  openCollapsibles.has(row.id) ? 'rotate-90' : ''
                                }`}
                              />
                            </Button>
                          </CollapsibleTrigger>
                        </Collapsible>
                      ) : null}
                    </TableCell>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.original.sessions?.length ? (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} className="p-0">
                        <Collapsible
                          open={openCollapsibles.has(row.id)}
                          onOpenChange={(isOpen) => {
                            setOpenCollapsibles((prev) => {
                              const newSet = new Set(prev);
                              if (isOpen) {
                                newSet.add(row.id);
                              } else {
                                newSet.delete(row.id);
                              }
                              return newSet;
                            });
                          }}
                        >
                          <CollapsibleContent className="bg-slate-50 p-4 hover:bg-slate-50">
                            <SessionDetailsTable
                              sessions={row.original.sessions.map(parseSession)}
                              reasonForTesting={row.original.reasonForTesting}
                            />
                          </CollapsibleContent>
                        </Collapsible>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} className="h-24 text-center">
                  {t('table.no_data')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-center gap-12 p-4">
        <Button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className="pagination-button"
        >
          {t('table.pagination.previous')}
        </Button>
        <span className="pagination-info">
          {t('table.page')} {table.getState().pagination.pageIndex + 1} {t('table.to')}{' '}
          {table.getPageCount()}
        </span>
        <Button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className="pagination-button"
        >
          {t('table.pagination.next')}
        </Button>
      </div>
    </>
  );
}
