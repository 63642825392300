import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@careos/react-ui/Dialog';
import { Spinner } from '@careos/react-ui/Spinner';
import { createFileRoute } from '@tanstack/react-router';
import type { WorkplaceTestingOrder } from 'careos-maestro';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSessionOptions } from '@/features/order/form/api/get-session-options';
import { SessionForm } from '@/features/order/form/components/session-form';
import { useOrderData } from '@/features/order/table/api/get-order-data';
import { createColumns } from '@/features/order/table/components/columns';
import { DataTable } from '@/features/order/table/components/data-table';

export const Route = createFileRoute('/_authenticated/orders')({
  component: OrdersComponent,
});

function OrdersComponent() {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<WorkplaceTestingOrder | null>(null);

  const { data: orderData, status: orderStatus, error } = useOrderData();
  const { data: sessionOptions, status: sessionStatus } = useSessionOptions();

  const handleOpenDialog = (order: WorkplaceTestingOrder) => {
    setSelectedOrder(order);
    setShowDialog(true);
  };

  const handleFormSubmitSuccess = () => {
    setShowDialog(false);
    setSelectedOrder(null);
  };

  const columns = createColumns(handleOpenDialog);

  if (orderStatus === 'pending' || sessionStatus === 'pending') {
    return (
      <div className="flex h-96 items-center justify-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (orderStatus === 'error' || sessionStatus === 'error') return <div>{error?.stack}</div>;

  return (
    <div className="px-2 py-10 md:container">
      <DataTable columns={columns} data={orderData} />
      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent className="max-h-full overflow-y-auto">
          <DialogHeader>
            <DialogTitle>{t('table.create_session')}</DialogTitle>
          </DialogHeader>
          {selectedOrder && sessionOptions && (
            <SessionForm
              order={selectedOrder}
              testTypeOptions={sessionOptions.testTypes}
              sampleTypeOptions={sessionOptions.sampleTypes}
              onSubmitSuccess={handleFormSubmitSuccess}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
