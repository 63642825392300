import { captureExceptionInSentry } from '@careos/react-ui/utils';
import {
  type DefaultOptions,
  MutationCache,
  QueryClient,
  type UseMutationOptions,
} from '@tanstack/react-query';
import type { FetchError } from 'ofetch';

export const queryConfig = {
  queries: {
    throwOnError: true,
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: 500,
    staleTime: 60_000,
  },
} satisfies DefaultOptions;

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
  mutationCache: new MutationCache({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: captureExceptionInSentry,
  }),
});

export type ApiFnReturnType<FnType extends (...args: any) => Promise<any>> = Awaited<
  ReturnType<FnType>
>;

export type QueryConfig<T extends (...args: any[]) => any> = Omit<
  ReturnType<T>,
  'queryKey' | 'queryFn'
>;

export type MutationConfig<MutationFnType extends (...args: any) => Promise<any>> =
  UseMutationOptions<ApiFnReturnType<MutationFnType>, FetchError, Parameters<MutationFnType>[0]>;
