import { useAuth0, type Auth0ContextInterface } from '@auth0/auth0-react';
import type { Auth0CareosUser } from '@careos/react-ui/AuthenticatedComponent';
import { Toaster } from '@careos/react-ui/Toast';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/react-router-devtools';

import { Header } from '@/components/header';

type TRouterContext = { auth: Auth0ContextInterface<Auth0CareosUser> };

export const Route = createRootRouteWithContext<TRouterContext>()({
  component: RootComponent,
});

function RootComponent() {
  const { isAuthenticated, logout, user } = useAuth0<Auth0CareosUser>();

  return (
    <>
      {isAuthenticated && user && <Header onLogout={logout} user={user} />}
      <Toaster />
      <main className="min-h-[calc(100dvh_-_58px)]">
        <Outlet />
      </main>
      <TanStackRouterDevtools />
      <ReactQueryDevtools />
    </>
  );
}
