import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@careos/react-ui/Dialog';
import { useTranslation } from 'react-i18next';

import { useGetRequisitionsBySessionId } from '../../../api/get-requisitions-by-session-id';

import { SessionRequisitionsDialogDataTable } from './dialog-data-table';
import { getRequisitionColumns } from './dialog-data-table-columns';

type SessionRequisitionsDialogContentProps = {
  sessionId: string;
};

export function SessionRequisitionsDialogContent({
  sessionId,
}: SessionRequisitionsDialogContentProps) {
  const { t } = useTranslation();
  const { data: dto, error } = useGetRequisitionsBySessionId({
    getRequisitionsBySessionIdParams: { sessionId },
  });
  if (error) {
    return <div>{error.stack}</div>;
  }
  if (dto) {
    const { requisitions } = dto;
    return (
      <DialogContent className="overflow-auto md:min-w-fit">
        <DialogHeader>
          <DialogTitle>{t('requisitions.title')}</DialogTitle>
        </DialogHeader>
        <DialogDescription />
        <SessionRequisitionsDialogDataTable
          data={requisitions}
          columns={getRequisitionColumns()}
          error={error}
        />
      </DialogContent>
    );
  }
}
