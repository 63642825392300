import type { RandomizationListUrlResponseDto } from '@careos/toxicology-types';
import { useMutation } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { MutationConfig } from '@/lib/react-query';

export const useCreateRandomizationListUploadUrl = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof createUrl>;
} = {}) => {
  const { post } = useCustomFetch();

  function createUrl(sessionId: string) {
    return post<RandomizationListUrlResponseDto>(`/randomization-list/upload-url/${sessionId}`, {});
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: createUrl,
  });
};
